import React, { useState, useEffect } from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Loading from "../Components/Loading";

function Contact() {
  const [loading, setLoading] = useState(false);
  return (
    <>
      {loading && <Loading />}
      <Header />
      <div className="no-bottom no-top" id="content">
        <div id="top"></div>

        <section id="subheader" className="jarallax text-light">
          <img
            src="images/background/subheader.jpg"
            className="jarallax-img"
            alt=""
          />
          <div className="center-y relative text-center">
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-center">
                  <h1>Contact Us</h1>
                </div>
                <div className="clearfix"></div>
              </div>
            </div>
          </div>
        </section>

        <section aria-label="section">
          <div className="container">
            <div className="row g-custom-x">
              {/* <div className="col-lg-8 mb-sm-30">
                <h3>Do you have any question?</h3>

                <form
                  name="contactForm"
                  id="contact_form"
                  className="form-border"
                  method="post"
                  action="#"
                >
                  <div className="row">
                    <div className="col-md-4 mb10">
                      <div className="field-set">
                        <input
                          type="text"
                          name="Name"
                          id="name"
                          className="form-control"
                          placeholder="Your Name"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-4 mb10">
                      <div className="field-set">
                        <input
                          type="text"
                          name="Email"
                          id="email"
                          className="form-control"
                          placeholder="Your Email"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-4 mb10">
                      <div className="field-set">
                        <input
                          type="text"
                          name="phone"
                          id="phone"
                          className="form-control"
                          placeholder="Your Phone"
                          required
                        />
                      </div>
                    </div>
                  </div>

                  <div className="field-set mb20">
                    <textarea
                      name="message"
                      id="message"
                      className="form-control"
                      placeholder="Your Message"
                      required
                    ></textarea>
                  </div>
                  <div
                    className="g-recaptcha"
                    data-sitekey="copy-your-site-key-here"
                  ></div>
                  <div id="submit" className="mt20">
                    <input
                      type="submit"
                      id="send_message"
                      value="Send Message"
                      className="btn-main"
                    />
                  </div>

                  <div id="success_message" className="success">
                    Your message has been sent successfully. Refresh this page
                    if you want to send more messages.
                  </div>
                  <div id="error_message" className="error">
                    Sorry there was an error sending your form.
                  </div>
                </form>
              </div> */}
              <div className="col-lg-4"></div>

              <div className="col-lg-4">
                <div className="de-box mb30">
                  <h4>US Office</h4>
                  <address className="s1">
                    <span>
                      <i className="id-color fa fa-map-marker fa-lg"></i>38
                      Vincent Road Hicksille NY 11801
                    </span>
                    <span>
                      <i className="id-color fa fa-phone fa-lg"></i>
                      +1(347)894-7091
                    </span>
                    <span>
                      <i className="id-color fa fa-phone fa-lg"></i>
                      +1(516)644-6600
                    </span>
                    <span>
                      <i className="id-color fa fa-envelope-o fa-lg"></i>
                      <a href="mailto:contact@example.com">
                        contact@orientyblackcarservice.com
                      </a>
                    </span>
                  </address>
                </div>
              </div>
              <div className="col-lg-4"></div>
            </div>
          </div>
        </section>
      </div>
      <a href="#" id="back-to-top"></a>
      <Footer />
    </>
  );
}

export default Contact;
