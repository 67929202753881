import React from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";

function About() {
  return (
    <>
      <Header />
      <div className="no-bottom no-top zebra" id="content">
        <div id="top"></div>

        <section id="subheader" className="jarallax text-light">
          <img
            src="images/background/subheader.jpg"
            className="jarallax-img"
            alt=""
          />
          <div className="center-y relative text-center">
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-center">
                  <h1>About Us</h1>
                </div>
                <div className="clearfix"></div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-6 wow fadeInRight">
                <h2>
                  We offer customers a wide range of&nbsp;
                  <span className="id-color">commercial cars&nbsp;</span>{" "}
                  and&nbsp;
                  <span className="id-color">luxury cars&nbsp;</span> for
                  travelling.
                </h2>
              </div>
              <div className="col-lg-6 wow fadeInRight" data-wow-delay=".25s">
                At our agency, we believe that everyone deserves to experience
                the pleasure of a reliable and comfortable vehicle, regardless
                of their budget. We have curated a diverse fleet of
                well-maintained cars, ranging from sleek sedans to spacious
                SUVs, all at competitive prices. With our streamlined booking
                process, you can quickly and conveniently reserve your pick-up
                and drop-off. Whether you need transportation for a business
                trip, family vacation, or simply want to enjoy a weekend
                getaway, we have flexible options to accommodate your schedule.
              </div>
            </div>
            <div className="spacer-double"></div>
            <div className="row text-center">
              <div className="col-md-3 col-sm-6 mb-sm-30">
                <div className="de_count wow fadeInUp" data-bgcolor="#f5f5f5">
                  <h3 className="timer" data-to="15425" data-speed="3000">
                    0
                  </h3>
                  Completed Orders
                </div>
              </div>
              <div className="col-md-3 col-sm-6 mb-sm-30">
                <div className="de_count wow fadeInUp" data-bgcolor="#f5f5f5">
                  <h3 className="timer" data-to="13653" data-speed="3000">
                    0
                  </h3>
                  Happy Customers
                </div>
              </div>
              <div className="col-md-3 col-sm-6 mb-sm-30">
                <div className="de_count wow fadeInUp" data-bgcolor="#f5f5f5">
                  <h3 className="timer" data-to="235" data-speed="3000">
                    0
                  </h3>
                  Vehicles Fleet
                </div>
              </div>
              <div className="col-md-3 col-sm-6 mb-sm-30">
                <div className="de_count wow fadeInUp" data-bgcolor="#f5f5f5">
                  <h3 className="timer" data-to="15" data-speed="3000">
                    0
                  </h3>
                  Years Experience
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section aria-label="section" className="jarallax text-light">
          <img src="images/background/8.jpg" className="jarallax-img" alt="" />
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <h2>Board of Directors</h2>
                <div className="spacer-20"></div>
              </div>

              <div className="col-lg-3 col-md-6 col-sm-6 mb30">
                <div className="f-profile text-center">
                  <div className="fp-wrap f-invert">
                    <div className="fpw-overlay">
                      <div className="fpwo-wrap">
                        <div className="fpwow-icons">
                          <a href="#">
                            <i className="fa fa-facebook fa-lg"></i>
                          </a>
                          <a href="#">
                            <i className="fa fa-twitter fa-lg"></i>
                          </a>
                          <a href="#">
                            <i className="fa fa-linkedin fa-lg"></i>
                          </a>
                          <a href="#">
                            <i className="fa fa-pinterest fa-lg"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="fpw-overlay-btm"></div>
                    <img
                      src="images/team/1.jpg"
                      className="fp-image img-fluid"
                      alt=""
                    />
                  </div>
                  <h4>Fynley Wilkinson</h4>
                  Chief Creative Officer
                </div>
              </div>

              <div className="col-lg-3 col-md-6 col-sm-6 mb30">
                <div className="f-profile text-center">
                  <div className="fp-wrap f-invert">
                    <div className="fpw-overlay">
                      <div className="fpwo-wrap">
                        <div className="fpwow-icons">
                          <a href="#">
                            <i className="fa fa-facebook fa-lg"></i>
                          </a>
                          <a href="#">
                            <i className="fa fa-twitter fa-lg"></i>
                          </a>
                          <a href="#">
                            <i className="fa fa-linkedin fa-lg"></i>
                          </a>
                          <a href="#">
                            <i className="fa fa-pinterest fa-lg"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="fpw-overlay-btm"></div>
                    <img
                      src="images/team/2.jpg"
                      className="fp-image img-fluid"
                      alt=""
                    />
                  </div>
                  <h4>Peter Welsh</h4>
                  Chief Technology Officer
                </div>
              </div>

              <div className="col-lg-3 col-md-6 col-sm-6 mb30">
                <div className="f-profile text-center">
                  <div className="fp-wrap f-invert">
                    <div className="fpw-overlay">
                      <div className="fpwo-wrap">
                        <div className="fpwow-icons">
                          <a href="#">
                            <i className="fa fa-facebook fa-lg"></i>
                          </a>
                          <a href="#">
                            <i className="fa fa-twitter fa-lg"></i>
                          </a>
                          <a href="#">
                            <i className="fa fa-linkedin fa-lg"></i>
                          </a>
                          <a href="#">
                            <i className="fa fa-pinterest fa-lg"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="fpw-overlay-btm"></div>
                    <img
                      src="images/team/3.jpg"
                      className="fp-image img-fluid"
                      alt=""
                    />
                  </div>
                  <h4>John Shepard</h4>
                  Chief Executive Officer
                </div>
              </div>

              <div className="col-lg-3 col-md-6 col-sm-6 mb30">
                <div className="f-profile text-center">
                  <div className="fp-wrap f-invert">
                    <div className="fpw-overlay">
                      <div className="fpwo-wrap">
                        <div className="fpwow-icons">
                          <a href="#">
                            <i className="fa fa-facebook fa-lg"></i>
                          </a>
                          <a href="#">
                            <i className="fa fa-twitter fa-lg"></i>
                          </a>
                          <a href="#">
                            <i className="fa fa-linkedin fa-lg"></i>
                          </a>
                          <a href="#">
                            <i className="fa fa-pinterest fa-lg"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="fpw-overlay-btm"></div>
                    <img
                      src="images/team/4.jpg"
                      className="fp-image img-fluid"
                      alt=""
                    />
                  </div>
                  <h4>Robyn Peel</h4>
                  Director of Finance
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <section aria-label="section">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 offset-lg-3 text-center">
                <h2>Features Hilight</h2>
                <div className="spacer-20"></div>
              </div>
              <div className="clearfix"></div>
              <div className="col-lg-3">
                <div
                  className="box-icon s2 p-small mb20 wow fadeInRight"
                  data-wow-delay=".5s"
                >
                  <i className="fa bg-color fa-trophy"></i>
                  <div className="d-inner">
                    <h4>First class services</h4>
                    Where luxury meets exceptional care, creating unforgettable
                    moments and exceeding your every expectation.
                  </div>
                </div>
                <div
                  className="box-icon s2 p-small mb20 wow fadeInL fadeInRight"
                  data-wow-delay=".75s"
                >
                  <i className="fa bg-color fa-road"></i>
                  <div className="d-inner">
                    <h4>24/7 road assistance</h4>
                    Reliable support when you need it most, keeping you on the
                    move with confidence and peace of mind.
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <img
                  src="images/misc/car.png"
                  alt=""
                  className="img-fluid wow fadeInUp"
                />
              </div>

              <div className="col-lg-3">
                <div
                  className="box-icon s2 d-invert p-small mb20 wow fadeInL fadeInLeft"
                  data-wow-delay="1s"
                >
                  <i className="fa bg-color fa-tag"></i>
                  <div className="d-inner">
                    <h4>Quality at Minimum Expense</h4>
                    Unlocking affordable brilliance with elevating quality while
                    minimizing costs for maximum value.
                  </div>
                </div>
                <div
                  className="box-icon s2 d-invert p-small mb20 wow fadeInL fadeInLeft"
                  data-wow-delay="1.25s"
                >
                  <i className="fa bg-color fa-map-pin"></i>
                  <div className="d-inner">
                    <h4>Quick Pick-Up & Drop-Off</h4>
                    Enjoy fast pickup and drop-off services, adding an extra
                    layer of ease to your car booking experience.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="section-img-with-tab">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-5 offset-lg-7">
                <h2>Only Quality For Clients</h2>
                <div className="spacer-20"></div>

                <ul
                  className="nav nav-pills mb-3"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="pills-home-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-home"
                      type="button"
                      role="tab"
                      aria-controls="pills-home"
                      aria-selected="true"
                    >
                      Luxury
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="pills-profile-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-profile"
                      type="button"
                      role="tab"
                      aria-controls="pills-profile"
                      aria-selected="false"
                    >
                      Comfort
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="pills-contact-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-contact"
                      type="button"
                      role="tab"
                      aria-controls="pills-contact"
                      aria-selected="false"
                    >
                      Prestige
                    </button>
                  </li>
                </ul>
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    <p>
                      We offer a meticulously curated collection of the most
                      sought-after luxury vehicles on the market. Whether you
                      prefer the sporty allure of a high-performance sports car,
                      the sophistication of a sleek and luxurious sedan, or the
                      versatility of a premium SUV, we have the perfect car to
                      match your discerning taste.
                    </p>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-profile"
                    role="tabpanel"
                    aria-labelledby="pills-profile-tab"
                  >
                    <p>
                      We prioritize your comfort and convenience throughout your
                      journey. We understand that a comfortable ride can make a
                      world of difference, whether you're embarking on a
                      business trip or enjoying a leisurely vacation. That's why
                      we offer a wide range of well-maintained, comfortable cars
                      that cater to your specific needs.
                    </p>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-contact"
                    role="tabpanel"
                    aria-labelledby="pills-contact-tab"
                  >
                    <p>
                      We understand that prestige goes beyond luxury. It's about
                      making a statement, embracing sophistication, and
                      indulging in the finer things in life. That's why we offer
                      an exclusive selection of prestigious cars that exude
                      elegance, style, and status.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="image-container col-md-6 pull-right"
            data-bgimage="url(images/background/5.jpg) center"
          ></div>
        </section>

        <section
          id="section-call-to-action"
          className="bg-color-2 pt60 pb60 text-light"
          style={{
            backgroundColor: "#179510",
          }}
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-8">
                <h2 className="s2">
                  Call us for further information. Orienty Black Car Service
                  customer care is here to help you anytime.
                </h2>
              </div>

              <div className="col-lg-4 text-lg-center text-sm-center">
                <div className="phone-num-big">
                  <i className="fa fa-phone"></i>
                  <span className="pnb-text"> Call Us Now </span>
                  <span className="pnb-num">(347)894-7091</span>
                </div>
                <a href="tel:13478947091" className="btn-main">
                  Contact Us
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
      <a href="#" id="back-to-top"></a>
      <Footer />
    </>
  );
}

export default About;
