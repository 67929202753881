import React, { useState, useRef, useEffect } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import baseUrl from "../Helper/config";
import axios from "axios";

function CheckoutForm(props) {
  const [userDetails, setUserDetails] = useState({});
  useEffect(() => {
    const storedUsername = JSON.parse(
      localStorage.getItem("__OrientyBlackCarService__")
    );
    if (storedUsername) {
      setUserDetails(storedUsername);
    }
  }, []);

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      console.error("[error]", error);
      props?.toast.warn(error?.message);
    } else {
      props?.setLoading(true);

      let config = {
        method: "post",
        url: `${baseUrl}/v1/createPayment`,
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          paymentMethodId: paymentMethod.id,
          amount: Math.floor(Number(props?.amount * 100)),
          carData: props?.car,
          userDetails: props?.userDetails,
          rideData: props?.rideData,
          taxCharged: props?.taxCharged,
          additionalDetails: props?.additionalDetails,
          additionalSeatsDetails: props?.additionalSeatsDetails,
        }),
      };
      axios
        .request(config)
        .then((response) => {
          props?.setLoading(false);
          if (response?.data?.success) {
            props?.toast.success("Success!! Ride scheduled.");
            setTimeout(() => {
              if (response?.data?.userDetails) {
                localStorage.setItem(
                  "__OrientyBlackCarService__",
                  JSON.stringify(response?.data?.userDetails)
                );
              }
              props?.navigate("/dashboard");
              window.location.reload();
            }, 2000);
          } else if (response?.data?.tokenExpired) {
            props?.navigate("/login");
            window.location.reload();
          } else {
            props?.toast.warn(response?.data?.message);
          }
        })
        .catch((error) => {
          props?.setLoading(false);
          //toast.warn("Error!! Please try again later.");
        });
    }
  };

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
    hidePostalCode: true, // Optionally hide postal code if not needed
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="card-element-container">
        <CardElement options={CARD_ELEMENT_OPTIONS} />
      </div>
      <button
        type="submit"
        disabled={!stripe}
        className="btn-main btn-fullwidth mt-3"
      >
        Pay & Book Ride
      </button>
    </form>
  );
}

export default CheckoutForm;
