import React from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import Home from "./Pages/home";
import Cars from "./Pages/cars";
import Car from "./Pages/car";
import Login from "./Pages/login";
import About from "./Pages/about";
import Register from "./Pages/register";
import Contact from "./Pages/contact";
import Dashboard from "./Pages/dashboard";
import ScrollToTop from "./Components/ScrollToTop";
import NotFound from "Pages/404";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="cars" element={<Cars />} />
        <Route path="/car" element={<Car />} />
        <Route path="about" element={<About />} />
        <Route path="contact" element={<Contact />} />
        <Route path="login" element={<Login />} />
        <Route path="register" element={<Register />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="dashboard-profile" element={<Dashboard />} />
        <Route path="dashboard-rides" element={<Dashboard />} />
        <Route path=":noPath" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
